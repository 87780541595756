<template>
  <router-view style="height: 100vh"></router-view>
</template>

<script setup>
import { onMounted, onUnmounted, watchEffect } from 'vue'
import { useStore } from 'vuex'

// import useNotifications from '../../hooks/useNotifications'
// import { useAuth0 } from '../../plugins/auth'
// import { types } from '../../store/mutation-types'

// const auth = useAuth0()
const store = useStore()
// const { markAsRead } = useNotifications()

onMounted(() => {
  watchEffect(() => {
    if (store.state.initialized) {
      // window.Intercom('boot', {
      //   app_id: process.env.VUE_APP_INTERCOM_APP_ID,
      //   email: auth.data?.user?.email,
      //   name: auth.data?.user?.name,
      //   user_id: auth.data?.user?.employeeId
      // })
      window.Intercom('show')
      // markAsRead('intercom.admin.replied').then(data => {
      //   if (data.data.count) {
      //     store.dispatch(types.NOTIFICATIONS_FETCH)
      //   }
      // })
      // store.commit(types.SET_INTERCOM_STATE, true)
    }
  })
})

onUnmounted(() => {
  window.Intercom('hide')
  // window.Intercom('shutdown')
  // store.commit(types.SET_INTERCOM_STATE, false)
})
</script>
